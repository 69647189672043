import React, { useEffect, useState, useRef } from "react";
import { ResetUnlockBanner } from "../../pages/reset-unlock/banner";
import { t } from "i18next";
import styles from "./newslettersubscribe.module.scss";
import TGInput from "../../../../shared/tg-input";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { NewsSubscribeData, Country } from "../../models/models";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscribeDataPending,
  resetSubscribeData,
} from "../../slice/subscribeSlice";
import { RootState } from "../../slice/RootReducer";
import { Form, Spinner } from "react-bootstrap";
import {
  fetchProfileRequest,
  fetchCRMProfileRequest,
} from "../../slice/fetchProfileSlice";
import TGCheckBox from "../../../../shared/tg-checkbox";
import termsConditionsData from "../../../../../public/json/preferencetermsConditions.json";
import TGIcon from "../../../../shared/tg-icon";
import { preferenceFromDataRequest } from "../../slice/newsletterPreferenceSlice";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import AccountLocked from "../../../../shared/login-widget/sign-in/account-locked";
import { useTranslation } from "react-i18next";
import TGDateDropdown from "../../../../shared/tg-date-dropdown";
import TGTypeahead from "../../../../shared/tg-typeahead";

export const NewsletterSubscribe = () => {
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isRopLogin = sessionStorage.getItem("isROPLoggedIn");
  const [subscribeData, setSubscribeData] = useState<NewsSubscribeData>({
    email: "",
    dateOfBirth: "",
    preferenceLocation: "",
    termsConditions: "",
  });
  const dispatch = useDispatch();
  const termConditionRef = useRef<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errors, setErrors] = useState<Partial<NewsSubscribeData>>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [isSignedUser, setIsSignedUser] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [isToggleOpen, setIsToggleOpen] = useState<string>("");

  const SubscriptionResponse = useSelector(
    (state: RootState) => state?.osciReducer?.subscribeReset
  );
  const [isDisable, setIsDisable] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [signedUserDob, setSignedUserDob] = useState("");
  const [isDobError, setIsDobError] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const crmProfileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.crmProfileData
  );
  const getCountryFromUrl = i18n.language?.split("-")?.[1]?.toUpperCase();
  const { countryList } = location.state || [];
  const defaultCountry = countryList?.find(
    (el: { code: string }) => el.code === getCountryFromUrl
  );
  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    if (defaultCountry !== null && defaultCountry != undefined) {
      setSelectedCountryValue(defaultCountry);
      setSubscribeData({
        ...subscribeData,
        preferenceLocation: defaultCountry.airportCityCode,
      });
      setErrors({ ...errors, preferenceLocation: "" });
    }
    if (isRopLogin == "true") {
      dispatch(fetchProfileRequest());
    }
    if (isRopLogin == "false") {
      dispatch(fetchCRMProfileRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(preferenceFromDataRequest());
  }, [dispatch]);
  useEffect(() => {
    if (Object.keys(profileResponse)?.length > 0) {
      setSubscribeData({ ...subscribeData, email: profileResponse.email });
      setStartDate(profileResponse.birthDate);
      setSignedUserDob(moment(profileResponse.birthDate).format("DD MMM YYYY"));
      setErrors({ ...errors, email: "" });
      setErrors({ ...errors, dateOfBirth: "" });
      setIsSignedUser(true);
      setIsValidEmail(true);
      setIsDisable(false);
    } else {
      setIsSignedUser(false);
      setIsDisable(true);
    }
  }, [dispatch, profileResponse]);

  useEffect(() => {
    if (Object.keys(crmProfileResponse)?.length > 0) {
      setSubscribeData({ ...subscribeData, email: crmProfileResponse.emailId });
      setStartDate(crmProfileResponse.dob);
      setSignedUserDob(moment(crmProfileResponse.dob).format("DD MMM YYYY"));
      setErrors({ ...errors, email: "" });
      setErrors({ ...errors, dateOfBirth: "" });
      setIsSignedUser(true);
      setIsValidEmail(true);
      setIsDisable(false);
    } else {
      setIsSignedUser(false);
      setIsDisable(true);
    }
  }, [dispatch, crmProfileResponse]);
  useEffect(() => {
    const dobDate = moment(signedUserDob, "DD MMM YYYY");
    const minAllowedDate = moment().subtract(16, "years");
    if (isSignedUser && dobDate.isAfter(minAllowedDate)) {
      setIsDobError(true);
    } else {
      setIsDobError(false);
    }
  }, [isSubmit]);
  useEffect(() => {
    if (SubscriptionResponse.subscribestatus.status == true) {
      setIsSuccess(true);
    } else {
      if (SubscriptionResponse.error.code == "S7002") {
        setErrors({ ...errors, email: SubscriptionResponse.error.message });
        dispatch(resetSubscribeData());
      }
      if (SubscriptionResponse.error.status == "400") {
        setErrors({ ...errors, email: SubscriptionResponse.error.message });
        dispatch(resetSubscribeData());
      }
      setIsSuccess(false);
    }
  }, [dispatch, SubscriptionResponse]);

  const handleCloseModal = () => {
    setIsSuccess(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setSubscribeData({ ...subscribeData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    let enableButton =
      subscribeData.dateOfBirth != null &&
      subscribeData.dateOfBirth != undefined &&
      subscribeData.dateOfBirth != ""
        ? false
        : true;
    setIsDisable(enableButton);
  };

  const isCheckValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleDateChange = (date: string, month: string, year: string) => {
    const formattedDob = `${year}-${month}-${date}`;
    setSubscribeData({ ...subscribeData, dateOfBirth: formattedDob });
    setStartDate(formattedDob);
    setErrors({ ...errors, dateOfBirth: "" });
    let enableButton =
      subscribeData.email != "" &&
      subscribeData.email != null &&
      subscribeData.email != undefined &&
      date != undefined &&
      date !== "" &&
      month != undefined &&
      month !== "" &&
      month != "00" &&
      year != undefined &&
      year !== ""
        ? false
        : true;
    setIsDisable(enableButton);
    setDate(date);
    setMonth(month);
    setYear(year);
  };
  const handleCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryValue(country);
    setSubscribeData({
      ...subscribeData,
      preferenceLocation:
        country != null && country != undefined
          ? country.airportCityCode
          : null,
    });
    setErrors({ ...errors, preferenceLocation: "" });
    setErrors({ ...errors, email: "" });
    setIsToggleOpen("");
  };

  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      setSubscribeData({
        ...subscribeData,
        termsConditions: value.toString(),
      });
      if (value == true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };
  const getFieldLabel = (fieldName: keyof NewsSubscribeData) => {
    const labels: { [key in keyof NewsSubscribeData]: string } = {
      email: t("signin_email_required"),
      dateOfBirth: t("error_reset_dob_required"),
      preferenceLocation: "Preferred Location Required",
      termsConditions: t("enroll_terms_condition"),
    };
    return labels[fieldName];
  };
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    setIsSubmit(true);
    event.preventDefault();
    const newErrors: Partial<NewsSubscribeData> = {};
    Object.entries(subscribeData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof NewsSubscribeData] = getFieldLabel(
          key as keyof NewsSubscribeData
        );
      } else if (key === "email" && !isCheckValidEmail(value)) {
        newErrors[key as keyof NewsSubscribeData] = t("reset_invalid_message");
        setIsValidEmail(false);
      } else if (key == "dateOfBirth") {
        if (date == "") {
          newErrors[key as keyof NewsSubscribeData] = t("enroll_date_required");
        } else if (month == "00") {
          newErrors[key as keyof NewsSubscribeData] = t(
            "enroll_month_required"
          );
        } else if (date !== "" && month !== "" && year == "") {
          newErrors[key as keyof NewsSubscribeData] = t("enroll_year_required");
        }
        if (date != "" && month != "" && year != "") {
          var validDate = moment(subscribeData.dateOfBirth);
          const dobDate = moment(subscribeData.dateOfBirth, "YYYY-MM-DD");
          const minAllowedDate = moment().subtract(16, "years");
          if (dobDate.isAfter(minAllowedDate)) {
            newErrors[key as keyof NewsSubscribeData] = t(
              "subscribe_age_validation"
            );
          } else if (!validDate.isValid()) {
            newErrors[key as keyof NewsSubscribeData] = t(
              "label_valid_dob_err"
            );
          }
        }

        // if (isSignedUser == true) {
        //   const dobDate = moment(signedUserDob, "DD MMM YYYY");

        //   const minAllowedDate = moment().subtract(16, "years");
        //   if (dobDate.isAfter(minAllowedDate)) {
        //     setIsDobError(true);
        //     newErrors[key as keyof NewsSubscribeData] = t(
        //       "subscribe_age_validation"
        //     );
        //   } else {
        //     setIsDobError(false);
        //   }
        // }
      } else if (key == "termsConditions") {
        if (subscribeData.termsConditions != "true") {
          newErrors[key as keyof NewsSubscribeData] = t(
            "enroll_terms_condition"
          );
        }
      }
    });
    subscribeData.dateOfBirth = startDate;
    subscribeData.preferenceLocation = selectedCountryValue?.airportCityCode;
    if (
      subscribeData.dateOfBirth != null &&
      subscribeData.dateOfBirth != undefined &&
      isSignedUser
    ) {
      const dobDate = moment(signedUserDob, "DD MMM YYYY");
      const minAllowedDate = moment().subtract(16, "years");
      if (dobDate.isAfter(minAllowedDate)) {
        setErrors({ ...errors, dateOfBirth: "error" });
      } else {
        setErrors({ ...errors, dateOfBirth: "" });
        delete newErrors.dateOfBirth;
      }
    }
    if (
      subscribeData.preferenceLocation != null &&
      subscribeData.preferenceLocation != undefined
    ) {
      setErrors({ ...errors, preferenceLocation: "" });
      delete newErrors.preferenceLocation;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      dispatch(
        getSubscribeDataPending({
          email: subscribeData.email,
          dateOfBirth: subscribeData.dateOfBirth,
          preferenceLocation: subscribeData.preferenceLocation,
        })
      );
    }
  };
  return (
    <ResetUnlockBanner
      imageText={t("label_subscribe_banner_header")}
      descriptionText1={t("label_subscribe_banner_content")}
      banner={
        isDesktopView
          ? styles.unsubscribeContainer
          : styles.mobileSubscirbeFirstContainer
      }
      imageTextClassName={
        isDesktopView
          ? styles.unsubscribetitleContainer
          : styles.mobileUnSubscribeFirstContainerText
      }
      titleContainer={styles.subscribetitleContainer}
      descriptionTextClassName={styles.mobileUnsubscribeDescriptionText}
      secondaryContainer={styles.secondUnsubscribeContainer}
    >
      <div className={styles.unsubscribeNewsletterContainer}>
        <div className={styles.secondaryUnsubscribeContainer}>
          <div className={styles.textUnSubscribeContainer}>
            {t("label_subscribe_form_header")}
          </div>
          {!isSignedUser && (
            <div className={styles.emailInputCOntainer}>
              <TGInput
                label="Email  "
                value={subscribeData.email}
                name="email"
                onChange={handleChange}
                isError={!!errors.email}
                errorLabel={errors.email}
                placeholder="Example: THAI@gmail.com"
                isEnroll={true}
                validation="language"
              />
            </div>
          )}
          {isSignedUser && (
            <div className={styles.signedUserContainer}>
              <span className={styles["tg-signed-label"]}>
                {t("label_reset_email")}
              </span>
              <span className={styles.text}>
                {isRopLogin == "true"
                  ? profileResponse.email
                  : crmProfileResponse.emailId}
              </span>
              {errors.email && (
                <div className={styles["tg-input-error-label"]}>
                  {errors.email}
                </div>
              )}
            </div>
          )}
          <div className={styles.primaryEnroll}>
            {isSignedUser && (
              <div className={styles.dobContainer}>
                <span className={styles["tg-signed-label"]}>
                  {t("label_reset_dob")}
                </span>
                <span className={styles.text}>{signedUserDob}</span>
                {isDobError && (
                  <span className={styles.dobError}>
                    {t("subscribe_age_validation")}
                  </span>
                )}
              </div>
            )}
            {!isSignedUser && (
              <TGDateDropdown
                isEnroll={true}
                isDesktop={true}
                label={t("label_reset_dob")}
                isError={!!errors.dateOfBirth}
                onDateChange={handleDateChange}
                errorLabel={errors.dateOfBirth}
                customClassName="newsletterSubscribe"
              />
            )}
            <div className={styles.preferenceContainer}>
              <Form.Group>
                <Form.Label className={styles.tgEnrollInputLabel}>
                  {t("label_preferred_news_rop")}
                </Form.Label>
                <TGTypeahead
                  id="preferredCountry"
                  labelKey={(option) => option.country}
                  options={countryList}
                  onChange={handleCountryChange}
                  selected={selectedCountry ? [selectedCountry] : []}
                  selectedValue={selectedCountryValue?.country}
                  isOpen={isToggleOpen === "preferredCountry"}
                  setIsOpen={(id) => setIsToggleOpen(id)}
                  error={errors.preferenceLocation}
                />
                {errors.preferenceLocation && (
                  <Form.Label className={styles.errorLabel}>
                    {t("label_preferred_news_rop_err_msg")}
                  </Form.Label>
                )}
              </Form.Group>
            </div>
          </div>
          <div
            className={
              isDesktopView
                ? styles.continueButton
                : styles.continueButtonMobile
            }
          >
            <div className={styles.border}></div>
            <div className={styles.checkSignup}>
              <TGCheckBox
                isHtmlContent={true}
                htmlContent={termsConditionsData.termConditiontext}
                onChange={checkedChange}
                checkref={termConditionRef}
                checked={isChecked}
                className="checkLabel"
                customLabel="checkLabel"
              />
              {errors.termsConditions && (
                <div className={styles.termsConditionsError}>
                  <TGIcon icon="error-icon" fillColor="" size="16" />
                  <div className={styles.termConditionText}>
                    {t("enroll_terms_condition")}
                  </div>
                </div>
              )}
            </div>
            <TGButtonVariants
              label={SubscriptionResponse.isLoading ? "" : "Subscribe"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColor="#684B9B"
              padding="16px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              borderDisabled="0.791px solid #E3D4FE"
              bgColorDisabled="#E3D4FE"
              boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColorHover="#684b9b"
              borderHover="0.791px solid #E3D4FE"
              boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              height="50px"
              width={isDesktopView ? "25%" : "100%"}
              onClick={handleSubmit}
              disabled={isDisable ? true : false}
            >
              {SubscriptionResponse.isLoading ? (
                <>
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{
                      width: "10px",
                      height: "10px",
                      margin: "5px",
                    }}
                  />
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{
                      width: "10px",
                      height: "10px",
                      margin: "5px",
                    }}
                  />
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{
                      width: "10px",
                      height: "10px",
                      margin: "5px",
                    }}
                  />
                </>
              ) : null}
            </TGButtonVariants>
          </div>
        </div>
      </div>
      {isSuccess && (
        <AccountLocked
          hidePanel={handleCloseModal}
          componentName="subscribenewsLetter"
        />
      )}
    </ResetUnlockBanner>
  );
};
