import React, { useState, useEffect } from "react";
import { t } from "i18next";
import styles from "./newsletterunsubscribe.module.scss";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { SuccessCard } from "../../../../shared/success-card";
import Login from "../../../../shared/login-widget/index";
import LoginWidget from "../../../../shared/login-widget/index";
import { useNavigate } from "react-router-dom";
import AccountLocked from "../../../../shared/login-widget/sign-in/account-locked";
import { NewsUnSubscribeData } from "../../models/models";
import {
  getResubscribeDataPending,
  resetReSubscribeData,
} from "../../slice/resubscribeSlice";
import { RootState } from "../../slice/RootReducer";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  email: any;
}

export const SuccessNewsletterUnSubscribe = (props: Props) => {
  const dispatch = useDispatch();
  const ReSubscriptionResponse = useSelector(
    (state: RootState) => state?.osciReducer?.resubscribeReset
  );
  const isMobileView: boolean = useScreenSize().device === "mobile";
  const navigate = useNavigate();
  const [isPanelClosed, setIsPanelClosed] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [errors, setErrors] = useState<Partial<NewsUnSubscribeData>>({});
  const [subscribeData, setSubscribeData] = useState<NewsUnSubscribeData>({
    email: "",
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const handlePanelShow = (): void => {
    setIsPanelClosed(!isPanelClosed);
  };
  const handleSignInPopupShow = (): void => {
    setShowSignIn(!showSignIn);
  };
  const handleSignInPopupClose = (callback?: Function): void => {
    setShowSignIn(false);
    navigate(window.location.origin);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setSubscribeData(props.email);
    if (ReSubscriptionResponse.resubscribeDetails.success == true) {
      setShowModal(true);
    } else {
      if (ReSubscriptionResponse.error.status == "400") {
        setErrors({ ...errors, email: ReSubscriptionResponse.error.message });
        dispatch(resetReSubscribeData());
      }
      setShowModal(false);
    }
  }, [dispatch, ReSubscriptionResponse]);

  const handleSubmit = () => {
    const newErrors: Partial<NewsUnSubscribeData> = {};
    setErrors(newErrors);
    dispatch(
      getResubscribeDataPending({
        email: subscribeData.email,
      })
    );
  };

  return (
    <>
      {isMobileView && (
        <div className={styles.conitnueMobileSecondContainer}>
          <span className={styles.primaryText}>Successfully unsubscribed</span>
          <span className={styles.secondarytext}>
            Your request has been successfully processed. Should you ever wish
            to rejoin us, we will be here with updates and offers. Until then,
            we wish you all the best on your journey.
          </span>
          <div className={styles.mobileSuccessContainer}>
            <SuccessCard
              iconName="home-icon"
              text={t("label_back_to_home")}
              redirection="route"
              handlePanelShow={handlePanelShow}
              componentName="newsletterUnsubscribe"
            />
            <SuccessCard
              iconName="mail-icon"
              text="Subscribe Again"
              redirection="sidebar"
              handlePanelShow={handleSubmit}
              componentName="newsletterUnsubscribe"
            />
          </div>
        </div>
      )}
      {!isMobileView && (
        <>
          <div className={styles.continueContent}>
            <span className={styles.primaryText}>
              Successfully unsubscribed
            </span>
            <span className={styles.secondarytext}>
              Your request has been successfully processed. Should you ever wish
              to rejoin us, we will be here with updates and offers. Until then,
              we wish you all the best on your journey.
            </span>
            <div className={styles.container}>
              <SuccessCard
                iconName="home-icon"
                text={t("label_back_to_home")}
                redirection="route"
                handlePanelShow={handlePanelShow}
                componentName="newsletterUnsubscribe"
              />
              <SuccessCard
                iconName="mail-icon"
                text="Subscribe Again"
                redirection="sidebar"
                handlePanelShow={handleSubmit}
                componentName="newsletterUnsubscribe"
              />
            </div>
          </div>
        </>
      )}

      {showModal && (
        <AccountLocked
          hidePanel={handleCloseModal}
          componentName="unsubscribenewsLetter"
        />
      )}
      {/* { showModal && <GuestSubscribe hidePanel={handleCloseModal}/>} */}
    </>
  );
};
