import React, { useEffect, useState } from "react";
import { ResetUnlockBanner } from "../../pages/reset-unlock/banner";
import styles from "./newsletterunsubscribe.module.scss";
import TGInput from "../../../../shared/tg-input";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { SuccessNewsletterUnSubscribe } from "./success";
import { NewsUnSubscribeData } from "../../models/models";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnsubscribeDataPending,
  resetUnSubscribeData,
} from "../../slice/unsubscribeSlice";
import { RootState } from "../../slice/RootReducer";
import { Spinner } from "react-bootstrap";
import {
  fetchCRMProfileRequest,
  fetchProfileRequest,
} from "../../slice/fetchProfileSlice";
import { useTranslation } from "react-i18next";

export const NewsletterUnsubscribe = () => {
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isRopLogin = sessionStorage.getItem("isROPLoggedIn");
  const [unSubscribeData, setUnSubscribeData] = useState<NewsUnSubscribeData>({
    email: "",
  });
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errors, setErrors] = useState<Partial<NewsUnSubscribeData>>({});
  const [isComponentName, setIsComponentName] = useState("");
  const [isSignedUser, setIsSignedUser] = useState(false);
  const UnSubscriptionResponse = useSelector(
    (state: RootState) => state?.osciReducer?.unsubscribeReset
  );
  const { t } = useTranslation();
  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const crmProfileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.crmProfileData
  );

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    if (isRopLogin == "true") {
      dispatch(fetchProfileRequest());
    }
    if (isRopLogin == "false") {
      dispatch(fetchCRMProfileRequest());
    }
  }, [dispatch]);
  useEffect(() => {
    if (Object.keys(profileResponse)?.length > 0) {
      setUnSubscribeData({ ...unSubscribeData, email: profileResponse.email });
      setIsSignedUser(true);
      setIsValidEmail(true);
    } else {
      setIsSignedUser(false);
    }
  }, [dispatch, profileResponse]);
  useEffect(() => {
    if (Object.keys(crmProfileResponse)?.length > 0) {
      setUnSubscribeData({
        ...unSubscribeData,
        email: crmProfileResponse.emailId,
      });
      setIsSignedUser(true);
      setIsValidEmail(true);
    } else {
      setIsSignedUser(false);
    }
  }, [dispatch, crmProfileResponse]);
  useEffect(() => {
    if (UnSubscriptionResponse.unsubscribestatus.status == true) {
      setIsSuccess(true);
    } else {
      if (
        UnSubscriptionResponse.error?.code != "" &&
        UnSubscriptionResponse.error?.code != undefined
      ) {
        if (UnSubscriptionResponse.error?.code == "S7001") {
          setErrors({ ...errors, email: t("label_already_subscribe_err_msg") });
        } else if (UnSubscriptionResponse.error?.code == "S7003") {
          setErrors({
            ...errors,
            email: t("label_already_unsubscribe_err_msg"),
          });
        }
      }
      setIsSuccess(false);
      setIsValidEmail(true);
    }
  }, [dispatch, UnSubscriptionResponse]);
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setUnSubscribeData({ ...unSubscribeData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (value.length > 0) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  function convertToTitleCase(name: any) {
    var splitName = name.toLowerCase().split(" ");
    for (var i = 0; i < splitName.length; i++) {
      splitName[i] =
        splitName[i].charAt(0).toUpperCase() + splitName[i].substring(1);
    }
    return splitName.join(" ");
  }

  function handleROPNavigate() {
    window.open(
      "https://www.thaiairways.com/en_TH/rop/About/AboutRoyal_ProgrammeUpdates.page",
      "_blank"
    );
  }

  function handlePrivacyNavigate() {
    window.open(
      "https://www.thaiairways.com/en/terms_of_use/privacy_policy.page",
      "_blank"
    );
  }

  const handleChildData = (name: any) => {
    // Update the name in the component's state
    setIsComponentName(name);
  };
  const isCheckValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const getFieldLabel = (fieldName: keyof NewsUnSubscribeData) => {
    const labels: { [key in keyof NewsUnSubscribeData]: string } = {
      email: t("signin_email_required"),
    };
    return labels[fieldName];
  };
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newErrors: Partial<NewsUnSubscribeData> = {};
    Object.entries(unSubscribeData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof NewsUnSubscribeData] = getFieldLabel(
          key as keyof NewsUnSubscribeData
        );
      } else if (key === "email" && !isCheckValidEmail(value)) {
        newErrors[key as keyof NewsUnSubscribeData] = t(
          "reset_invalid_message"
        );
        setIsValidEmail(false);
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      dispatch(
        getUnsubscribeDataPending({
          email: unSubscribeData.email,
        })
      );
    }
  };
  return (
    <ResetUnlockBanner
      imageText={t("label_unsubscribe_button")}
      descriptionText1={t("label_unsubscribe_banner_content")}
      banner={
        isDesktopView
          ? styles.unsubscribeContainer
          : styles.mobileUnsubscirbeFirstContainer
      }
      imageTextClassName={
        isDesktopView
          ? styles.unsubscribetitleContainer
          : styles.mobileUnSubscribeFirstContainerText
      }
      titleContainer={styles.unsubscribetitleContainer}
      descriptionTextClassName={styles.mobileUnsubscribeDescriptionText}
      secondaryContainer={styles.secondUnsubscribeContainer}
    >
      {!isSuccess && (
        <>
          <div className={styles.unsubscribeNewsletterContainer}>
            <div className={styles.secondaryUnsubscribeContainer}>
              <div className={styles.textUnSubscribeContainer}>
                {t("label_subscribe_form_header")}
              </div>
              {!isSignedUser && (
                <div className={styles.emailInputCOntainer}>
                  <TGInput
                    label="Email  "
                    value={unSubscribeData.email}
                    name="email"
                    onChange={handleChange}
                    isError={!!errors.email}
                    errorLabel={errors.email}
                    placeholder="Example: THAI@gmail.com"
                    validation="language"
                  />
                </div>
              )}
              {isSignedUser && (
                <div className={styles.signedUserContainer}>
                  <span className={styles.text}>
                    {convertToTitleCase(
                      isRopLogin == "true"
                        ? profileResponse.firstName
                        : crmProfileResponse.firstName
                    )}{" "}
                    {convertToTitleCase(
                      isRopLogin == "true"
                        ? profileResponse.lastName
                        : crmProfileResponse.lastName
                    )}
                  </span>
                  <span className={styles.text}>
                    {isRopLogin == "true"
                      ? profileResponse.email
                      : crmProfileResponse.emailId}
                  </span>
                  {errors.email && (
                    <div className={styles["tg-input-error-label"]}>
                      {errors.email}
                    </div>
                  )}
                </div>
              )}
              <div
                className={
                  isDesktopView
                    ? styles.continueButton
                    : styles.continueButtonMobile
                }
              >
                <div className={styles.border}></div>
                <div className={styles.tnc}>
                  For further information please read our{" "}
                  <span
                    className={styles.royalTerms}
                    onClick={handleROPNavigate}
                  >
                    Royal Orchid Plus Terms & Conditions{" "}
                  </span>{" "}
                  and {isDesktopView ? <br /> : ""}
                  <span
                    className={styles.privacyNotice}
                    onClick={handlePrivacyNavigate}
                  >
                    Privacy Notice
                  </span>
                </div>
                <TGButtonVariants
                  label={UnSubscriptionResponse.isLoading ? "" : "Unsubscribe"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#684B9B"
                  padding="16px"
                  textColor="#FFFFFF"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  borderDisabled="0.791px solid #E3D4FE"
                  bgColorDisabled="#E3D4FE"
                  boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColorHover="#684b9b"
                  borderHover="0.791px solid #E3D4FE"
                  boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  height="50px"
                  width={isDesktopView ? "25%" : "100%"}
                  onClick={handleSubmit}
                  disabled={isValidEmail ? false : true}
                >
                  {UnSubscriptionResponse.isLoading ? (
                    <>
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                    </>
                  ) : null}
                </TGButtonVariants>
              </div>
            </div>
          </div>
        </>
      )}
      {/* If the condition got success, page has to be redirect to Success page */}
      {isSuccess && <SuccessNewsletterUnSubscribe email={unSubscribeData} />}
    </ResetUnlockBanner>
  );
};
