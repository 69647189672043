import styles from "./qualifying-miles-calculator.module.scss";
import silverCardImage from "../../../../../public/assets/silver_tier.png";
import goldCardImage from "../../../../../public/assets/gold_tier.png";
import platCardImage from "../../../../../public/assets/platinum_tier.png";
import TGIcon from "../../../../shared/tg-icon";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getMemberTierCalclulatorPending } from "../../slice/fetchMemberStatusCalculatorSlice";
import { fetchProfileRequest } from "../..//slice/fetchProfileSlice";
import { t } from "i18next";
import { ProgressBar } from "../member-status-calculator/progressBar";
// import TGDateCalendar from "../../../../shared/tg-new-datepicker";

const QualifyingMilesCalculator = () => {
  const dispatch = useDispatch();
  const milesRef = useRef<any>(null);

  let viewerWidth = milesRef?.current?.getBoundingClientRect().width;

  const [currentDate, setCurrentDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(7);
  const selectorRef = useRef<any>(null);
  const progressRef = useRef<any>(null);
  const [criteria1Array, setCriteria1Array] = useState<any>([]);
  const [criteria2Array, setCriteria2Array] = useState<any>([]);

  const { privilegeCard } = useSelector(
    (state: RootState) => state?.osciReducer?.profile?.profileData
  );

  const memberStatusTierDatas = useSelector(
    (state: RootState) => state?.osciReducer?.memberStatusCalculator
  );

  useEffect(() => {
    dispatch(fetchProfileRequest());
  }, []);

  useEffect(() => {
    dispatch(
      getMemberTierCalclulatorPending({
        upgradeSimulationDate: moment(currentDate).format("YYYY-MM-DD"),
      })
    );
  }, [currentDate]);

  useEffect(() => {
    let criteriaList =
      memberStatusTierDatas?.memberTierData?.Upgrade?.FlatListData;
    if (criteriaList) {
      criteriaList.forEach((element) => {
        if (element.Criteria == "Criteria 1") {
          setCriteria1Array(element.Dates);
        }
        if (element.Criteria == "Criteria 2") {
          setCriteria2Array(element.Dates);
        }
      });
    }
  }, [memberStatusTierDatas]);

  const startDate = new Date();
  const endDate = new Date();
  endDate.setFullYear(startDate.getFullYear() + 1);

  const totalDays = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  useEffect(() => {
    const daysFromStart = Math.floor(
      (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const newValue = 7 + (daysFromStart / totalDays) * (92 - 7);
    setValue(Math.round(newValue));
    selectorRef.current.style.left = `${Math.round(newValue)}%`;
    progressRef.current.style.width = `${Math.round(newValue)}%`;
  }, [currentDate]);

  const handleSliderChange = (e: any) => {
    setValue(e.target.value);
    const newValue = Number(e.target.value);
    setValue(newValue);
    const daysFromStart = Math.floor(((newValue - 7) / (92 - 7)) * totalDays);
    const newDate = new Date(
      startDate.getTime() + daysFromStart * 24 * 60 * 60 * 1000
    );
    setCurrentDate(newDate);
    if (selectorRef.current && progressRef.current) {
      selectorRef.current.style.left = `${e.target.value}%`;
      progressRef.current.style.width = `${e.target.value}%`;
    }
  };

  const handleLabelClick = () => {
    setIsOpen(true);
  };

  const incrementDate = () => {
    if (currentDate.toDateString() !== endDate.toDateString()) {
      setCurrentDate(
        (prevDate) => new Date(prevDate.setDate(prevDate.getDate() + 1))
      );
    }
  };
  const decrementDate = () => {
    if (currentDate.toDateString() !== new Date().toDateString()) {
      setCurrentDate(
        (prevDate) => new Date(prevDate.setDate(prevDate.getDate() - 1))
      );
    }
  };

  return (
    <div
      className={`${styles.qualifyingMilesCalculatorMain} global-content-padding`}
    >
      <div className={styles.qualifyingMilesCalculatorTitle}>
        unlock{" "}
        <span
          className={`${
            privilegeCard === "MEMBER"
              ? styles.qualifyingMilesCalculatorSilver
              : privilegeCard === "SILVER"
              ? styles.qualifyingMilesCalculatorGold
              : styles.qualifyingMilesCalculatorPlat
          }`}
        >
          {privilegeCard === "MEMBER"
            ? "silver"
            : privilegeCard === "SILVER"
            ? "gold"
            : "platinum"}
        </span>{" "}
        status
      </div>
      <div className={styles.qualifyingMilesCalculatorPara}>
        Slide the plane to a future date to see the impact on your qualifying
        miles including your upcoming trips.
      </div>
      <div className={styles.qualifyingMilesCalculatorSeperate}>
        <div className={styles.qualifyingMilesCalculatorProgressMain}>
          <div className={styles.qualifyingMilesCalculatorDateMain}>
            <div
              className={`${styles.qualifyingMilesCalculatorDateArrow} ${
                currentDate.toDateString() === new Date().toDateString()
                  ? styles.qualifyingMilesCalculatorDateArrowDisable
                  : styles.qualifyingMilesCalculatorDateArrowEnable
              }`}
              onClick={decrementDate}
            >
              <TGIcon
                icon="chevron-left-arrow"
                fillColor=""
                size=""
                strokeColor={
                  currentDate.toDateString() === new Date().toDateString()
                    ? "#CACAD9"
                    : "#181B47"
                }
              />
            </div>
            <div>
              <label
                onClick={handleLabelClick}
                className={styles.qualifyingMilesCalculatorDateLabel}
              >
                {moment(currentDate).format("dddd, DD MMMM YYYY")}
              </label>
              <ReactDatePicker
                selected={currentDate}
                onChange={(date) => {
                  setCurrentDate(date);
                  setIsOpen(false);
                }}
                monthsShown={2}
                minDate={new Date()}
                maxDate={endDate}
                open={isOpen}
                onClickOutside={() => setIsOpen(false)}
                className={styles.qualifyingMilesCalculatorDateDisplay}
              />
            </div>
            <div
              className={`${styles.qualifyingMilesCalculatorDateArrow}  ${
                currentDate.toDateString() === endDate.toDateString()
                  ? styles.qualifyingMilesCalculatorDateArrowDisable
                  : styles.qualifyingMilesCalculatorDateArrowEnable
              }`}
              onClick={incrementDate}
            >
              <TGIcon
                icon="chevron-right-arrow"
                fillColor=""
                size=""
                strokeColor={
                  currentDate.toDateString() === endDate.toDateString()
                    ? "#CACAD9"
                    : "#181B47"
                }
              />
            </div>
          </div>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              type="range"
              min="7"
              max="92"
              value={value}
              className={styles.qualifyingMilesCalculatorSlider}
              onChange={handleSliderChange}
            />
            <div
              className={styles.qualifyingMilesCalculatorSelector}
              ref={selectorRef}
            >
              <div className={styles.qualifyingMilesCalculatorSelectBtn}></div>
            </div>
            <div
              className={styles.qualifyingMilesCalculatorPrgBar}
              ref={progressRef}
            ></div>
          </div>
        </div>
        {/* <div>
          <h3>Date progress</h3>
          <p>Start Date: {startDate.toDateString()}</p>
          <p>End Date: {endDate.toDateString()}</p>
          <p>Current Date: {currentDate.toDateString()}</p>
          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={handleProgressChange}
          />
          <p>Progress: {progress}%</p>
        </div> */}

        <div className={styles.qualifyingMilesCalculatorCalenderBuyMain}>
          <div
            className={styles.qualifyingMilesCalculatorCalenderBuy}
            ref={milesRef}
          >
            <div className={styles.qualifyingMilesCalculatorCalenderBuyInner}>
              <div className={styles.qualifyingMilesCalculatorCalenderBuyFirst}>
                <img
                  src={
                    privilegeCard === "MEMBER"
                      ? silverCardImage
                      : privilegeCard === "SILVER"
                      ? goldCardImage
                      : platCardImage
                  }
                  alt="tierImage"
                />
                <div
                  className={`${styles.qualifyingMilesCalculatorPara} ${styles.qualifyingMilesCalculatorParaSub} `}
                >
                  Fast track and enjoy the perks of Gold membership sooner.
                </div>
              </div>
              <div className={styles.qualifyingMilesCalculatorStatusMilesMain}>
                <div className={styles.qualifyingMilesCalculatorStatusMiles}>
                  Buy Status Miles
                </div>
                <TGIcon icon="arrow-right-sm" size={""} fillColor={"#684B9B"} />
              </div>
            </div>
          </div>
          <div
            className={`${styles.qualifyingMilesCalculatorStatusCriteriaMain} ${
              privilegeCard === "MEMBER"
                ? styles.qualifyingMilesCalculatorStatusCriteriaSilverBg
                : privilegeCard === "SILVER"
                ? styles.qualifyingMilesCalculatorStatusCriteriaGoldBg
                : styles.qualifyingMilesCalculatorStatusCriteriaPlatBg
            }`}
            style={{ width: `${viewerWidth}px` }}
          >
            <div className={styles.qualifyingMilesCalculatorStatusCriteriaSub}>
              <div
                className={styles.qualifyingMilesCalculatorStatusCriteriaSubOne}
              >
                <div>
                  <div
                    className={
                      styles.qualifyingMilesCalculatorStatusCriteriaSubTitle
                    }
                  >
                    Criteria 1
                  </div>
                  <div
                    className={`${styles.qualifyingMilesCalculatorPara} ${styles.qualifyingMilesCalculatorStatusCriteriaSubPara} `}
                  >
                    {Number(
                      criteria1Array[0]?.RequiredPoints
                    )?.toLocaleString()}{" "}
                    Qualifying Miles are required in a 12 months rolling period.
                  </div>
                </div>
                <ProgressBar
                  value={criteria1Array[0]?.TotalPoints}
                  maxValue={criteria1Array[0]?.RequiredPoints}
                  dataRange={
                    privilegeCard == "GOLD"
                      ? `${moment(criteria1Array[0]?.BetweenStartDate).format(
                          "DD MMM YYYY"
                        )} - ${moment(criteria1Array[0]?.BetweenEndDate).format(
                          "DD MMM YYYY"
                        )} (${t("period_1")})`
                      : `${moment(criteria1Array[0]?.BetweenStartDate).format(
                          "DD MMM YYYY"
                        )} - ${moment(criteria1Array[0]?.BetweenEndDate).format(
                          "DD MMM YYYY"
                        )}`
                  }
                  privilageCard={
                    privilegeCard === "MEMBER"
                      ? "SILVER"
                      : privilegeCard === "SILVER"
                      ? "GOLD"
                      : "PLATINUM"
                  }
                  isInActive={false}
                />
              </div>
              <div className={styles.qualifyingMilesCalculatorSepMain}>
                <div className={styles.qualifyingMilesCalculatorSep}></div>
                <div
                  className={`${styles.qualifyingMilesCalculatorPara} ${styles.qualifyingMilesCalculatorStatusCriteriaSubPara} `}
                >
                  {privilegeCard === "GOLD" ? "and" : "or"}
                </div>
                <div className={styles.qualifyingMilesCalculatorSep}></div>
              </div>
              <div
                className={styles.qualifyingMilesCalculatorStatusCriteriaSubOne}
              >
                {privilegeCard !== "GOLD" && (
                  <div>
                    <div
                      className={
                        styles.qualifyingMilesCalculatorStatusCriteriaSubTitle
                      }
                    >
                      Criteria 2
                    </div>
                    <div
                      className={`${styles.qualifyingMilesCalculatorPara} ${styles.qualifyingMilesCalculatorStatusCriteriaSubPara} `}
                    >
                      {Number(
                        criteria2Array[0]?.RequiredPoints
                      )?.toLocaleString()}{" "}
                      Qualifying Miles are required in a 24 months rolling
                      period.
                    </div>
                  </div>
                )}
                <ProgressBar
                  value={criteria2Array[0]?.TotalPoints}
                  maxValue={criteria2Array[0]?.RequiredPoints}
                  dataRange={
                    privilegeCard == "GOLD"
                      ? `${moment(criteria2Array[0]?.BetweenStartDate).format(
                          "DD MMM YYYY"
                        )} - ${moment(criteria2Array[0]?.BetweenEndDate).format(
                          "DD MMM YYYY"
                        )} (${t("period_2")})`
                      : `${moment(criteria2Array[0]?.BetweenStartDate).format(
                          "DD MMM YYYY"
                        )} - ${moment(criteria2Array[0]?.BetweenEndDate).format(
                          "DD MMM YYYY"
                        )}`
                  }
                  privilageCard={
                    privilegeCard === "MEMBER"
                      ? "SILVER"
                      : privilegeCard === "SILVER"
                      ? "GOLD"
                      : "PLATINUM"
                  }
                  isInActive={false}
                />
              </div>
            </div>
            <div
              className={styles.qualifyingMilesCalculatorStatusMilesPrivileges}
            >
              <div
                className={`${styles.qualifyingMilesCalculatorStatusMiles} ${
                  privilegeCard === "MEMBER"
                    ? styles.qualifyingMilesCalculatorStatusMilesPrivilegesTextViolet
                    : styles.qualifyingMilesCalculatorStatusMilesPrivilegesText
                }`}
              >
                {privilegeCard === "MEMBER"
                  ? "Silver"
                  : privilegeCard === "SILVER"
                  ? "Gold"
                  : "Platinum"}{" "}
                Status Privileges
              </div>
              <TGIcon
                icon={"arrow-right"}
                size="24px"
                fillColor={privilegeCard === "MEMBER" ? "#2C3059" : "white"}
              ></TGIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualifyingMilesCalculator;
